import React from 'react';
import './Programs.css';
import {programsData} from '../../data/programsData';
import RightArrow from '../../assets/rightArrow.png';

const Programs = () => {
  return (
   <div className="programs" id="programs">
    {/* Header */}
    <div className="programs-header">
        <span className='stroke-text'>Explore our </span>
        <span>programs</span>
        <span className='stroke-text'>to shape yourself.</span>
    </div>
    
    {/* program categories */}
    <div className="program-category">
        {programsData.map((program)=>(
            <div className="category">
                {program.image}
                <span>{program.heading}</span>
                <span>{program.details}</span>
                <div className="join-now">
                    <span>Join now</span>
                    <img src={RightArrow} alt="" srcset="" />

                </div>
            </div>
        ))}
    </div>

   </div>
  );
}

export default Programs;

